import React, { useEffect, useState, useContext } from 'react';
import { HiOutlineHome, HiOutlineDocumentReport } from 'react-icons/hi';
import { IoPeople } from "react-icons/io5";
import { RiAdminLine } from "react-icons/ri";
import Compo from '../Others/Compo'
import Navigation from './Navigation';
import axios from 'axios';
import LineChart from './LineChart';
import { ReportContext } from '../Reports/ReportContext';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../utils/globals';
import Footer from './Footer';

function Panel() {
  let estate_id = localStorage.getItem('estate_id');
  const [residenceCount, setResidenceCount] = useState(0);
  const [ireportCount, setIreportCount] = useState(0);
  const [pendingReports, setPendingReports] = useState([]);
  const [newReport, setNewreport] = useState([]);
  const [totalAdminUsers, setTotalAdminUsers] = useState(0);
  const { viewedReports, markReportAsViewed } = useContext(ReportContext);
  const [houses, setTotalHouses] = useState(0);
  const [paymentData, setPaymentData] = useState([]);

  let is__admin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');
  let fullname = localStorage.getItem('fullname');
  let firstname = localStorage.getItem('firstname');
  let isAdmin = is__admin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;


  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 0 && hour < 12) {
      return 'Good Morning';
    } else if (hour >= 12 && hour < 17) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };


  useEffect(() => {
    const viewedReportsStorage =
      JSON.parse(localStorage.getItem('viewedReports')) || [];
    markReportAsViewed(viewedReportsStorage);

    const flattenedViewedReports = viewedReportsStorage.flat(Infinity);
    localStorage.setItem(
      'viewedReports',
      JSON.stringify(flattenedViewedReports)
    );
  }, [markReportAsViewed]);




  useEffect(() => {
    const fetchTotalHousesCount = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/houses/total/estate/${estate_id}`
        );
        setTotalHouses(response.data.total); // Set the total houses count
      } catch (error) {
        console.error('Error fetching total houses count:', error);
      }
    };

    fetchTotalHousesCount();
  }, [estate_id]); // Include `estate_id` in the dependency array


  useEffect(() => {
    async function fetchPaymentData() {
      try {
        const response = await axios.get(
          `${BASE_URL}/payments/estate/${estate_id}`
        );
        setPaymentData(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    // fetchIreport();
    fetchResidentCount();
    fetchIreportCount();
    fetchUsers();
    fetchPaymentData();
  }, [estate_id]);

  const updateLocalStorage = (pendingReportsData) => {
    const readStatus = pendingReportsData.reduce((status, ireport) => {
      status[ireport._id] = ireport.read;
      return status;
    }, {});

    localStorage.setItem(
      'pendingReportsReadStatus',
      JSON.stringify(readStatus)
    );
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/estate/${estate_id}`);
      const adminCount = response.data.totalAdmins;
      // console.log(adminCount);

      setTotalAdminUsers(adminCount);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchIreportCount = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/ireports/total/estate/${estate_id}`
      );

      const count = response.data.totalIreports;
      setIreportCount(count);
    } catch (error) {
      console.error(error);
    }
  };


  const fetchResidentCount = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/users/total/estate/${estate_id}`
      );

      const count = response.data;

      setResidenceCount(count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchResidentCount();
  }, [fetchResidentCount]);

  useEffect(() => {
    const fetchIreport = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/ireports/estate/${estate_id}`
        );
        console.log(response);

        setPendingReports(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchIreport();
  }, [estate_id]);


  useEffect(() => {
    if (isLoggedIn && fullname && firstname) {
      // const user_id = localStorage.getItem('user_id');
    }
  }, [isLoggedIn, fullname, firstname]);


  const handleViewIreport = async (ireportId) => {
    try {
      await axios.put(`${BASE_URL}/ireports/notifications/${ireportId}`);

      const response = await axios.get(
        `${BASE_URL}/ireports/estate/${estate_id}`
      );
      setPendingReports(response.data);
      markReportAsViewed(ireportId);
      localStorage.setItem('viewedReports', JSON.stringify(viewedReports));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchUnreadReports = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/ireports/unread/estate/${estate_id}`
        );
        setNewreport(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUnreadReports();
  }, [viewedReports]);






  return (
    <div className='estate-background'>
      {isLoggedIn && isAdmin ? (
        <div className='panel d-flex'>
          <div className='rr'>
            <Compo />
          </div>

          <div className='main--side'>
            <Navigation />
            <br />
            <div className='container '>
              <h5>
                {getGreeting()}, {fullname}
              </h5>
              <h5>DASHBOARD</h5>
              <br />
              <div className='media__cards'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='cardOne d-flex '>
                      <div className='cd1'>
                        <h4>Total Registered Residents</h4>
                        <h5>{residenceCount}</h5>
                      </div>
                      <div className='card-color text-white'>
                        <span className='card-icon'>
                          <IoPeople />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='cardOne d-flex'>
                      <div className='cd1'>
                        <h4>Total Submitted iReports</h4>
                        <h5>{ireportCount}</h5>
                      </div>
                      <div className='card-colorTwo text-white'>
                        <span className='card--icon'>
                          <HiOutlineDocumentReport />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='cardOne d-flex'>
                      <div className='cd1'>
                        <h4>System Administrators</h4>
                        <h5>{totalAdminUsers}</h5>
                      </div>
                      <div className='card-colorThr text-white'>
                        <span className='card-icon'>
                          <RiAdminLine />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='cardOne d-flex'>
                      <div className='cd1'>
                        <h4>Total Registered Houses</h4>
                        <h5>{houses}</h5>
                        <div className='card-arr d-flex gap-2'></div>
                      </div>
                      <div className='card-colorFr text-white'>
                        <span className='card-icon'>
                          <HiOutlineHome />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div className='media_card'>
                <div className='media_card_section'>
                  <div className='mediaCard_sec1 d-flex'>
                    <div className='media_1'>
                      <h6>Total Registered Residents</h6>
                      <h5>{residenceCount}</h5>
                    </div>

                    <div className='card-color text-white'>
                      <span className='card-icon'>
                        <IoPeople />
                      </span>
                    </div>
                  </div>
                  <div className='mediaCard_sec2 d-flex'>
                    <div className='media_1'>
                      <h6>Total Submitted iReports</h6>
                      <h5>{ireportCount}</h5>
                    </div>

                    <div className='card-colorTwo text-white'>
                      <span className='card-icon'>
                        <HiOutlineDocumentReport />
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className='media_card_section'>
                  <div className='mediaCard_sec3 d-flex'>
                    <div className='media_1'>
                      <h6>System Administrators</h6>
                      <h5>{totalAdminUsers}</h5>
                    </div>

                    <div className='card-colorThr text-white'>
                      <span className='card-icon'>
                        <RiAdminLine />
                      </span>
                    </div>
                  </div>
                  <div className='mediaCard_sec4 d-flex'>
                    <div className='media_1'>
                      <h6>Total Registered Houses</h6>
                      <h5>{houses}</h5>
                    </div>

                    <div className='card-colorFr text-white'>
                      <span className='card-icon'>
                        <HiOutlineHome />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='row_one'
                style={{ marginBottom: '150px', display: 'flex' }}
              >
                <div className='barchart'>
                  <LineChart paymentData={paymentData} />
                </div>

                {/* <div className='col-sm-4'>
                  <div className='tabsTwo'>
                    <div className='inside_Two'>
                      <div className='x--box'>
                        <div className='x-box'></div>
                      </div>
                      <br />
                      <br />
                      <div className='x-box-cont d-flex'>
                        <strong>
                          Desktop<p className='parag'>50%</p>
                        </strong>
                        <strong>
                          Tab<p className='parags'>40%</p>
                        </strong>
                        <strong>
                          Mobile<p className='paragg'>32%</p>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              {/* <div className='col-sm-4'>
                <div className='tabsTwwo'>
                  <div className='x--box'>
                    <div className='x-box'></div>
                  </div>
                  <br />
                  <br />
                  <div className='x-box-cont d-flex'>
                    <h4>Payment Chat</h4>
                  </div>
                </div>
              </div> */}
            </div>

            <div className='tab---les' style={{ width: '95%', margin: 'auto' }}>
              <div className='pen-ding'>
                <div className='pending__reports'>
                  <h5>Pending iReports</h5>
                </div>
                <div className='pending-box'></div>
              </div>
              <div className='table__records'>
                <table
                  className='table table-bordered table-hover '
                  style={{ backgroundColor: '#D5E2EE' }}
                >
                  <thead>
                    <tr>
                      <th scope='col'>S/NO</th>
                      <th scope='col'>NAME</th>
                      <th scope='col'>REPORT</th>

                      <th scope='col'>RISK</th>
                      <th scope='col'>DATE</th>
                      <th scope='col'>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newReport.map((item, index) => (
                      <tr
                        key={item._id}
                        style={{
                          backgroundColor: viewedReports.includes(item.read)
                            ? '#cccccc'
                            : '#d5e2ee',
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{item.user_id?.firstname}</td>
                        <td className='report-cell'>{item.description}</td>

                        <td>{item.risk}</td>
                        <td>{item.date}</td>
                        <td>
                          <Link
                            to={`/ReportDetails/${item._id}`}
                            onClick={() => handleViewIreport(item._id)}
                          >
                            <button
                              className='views'
                              style={{
                                border: 'none',
                                background: 'transparent',
                                color: 'blue',
                                fontWeight: '700',
                              }}
                            >
                              {viewedReports.includes(item._id)
                                ? 'VIEWED'
                                : 'VIEW'}
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
         
        </div>
        
      ) : (
        <>You do not have the permission to view this page</>
      )}
       <Footer/>
    </div>
    
  );
}

export default Panel;
